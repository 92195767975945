import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';

function ResetPassword() {
    const navigate = useNavigate();
    const { sendEvent, setLoading } = useCommons();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const tokenId = searchParams.get('tokenId');
    const token = searchParams.get('token');
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleResetPassword = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }
        try {
            sendEvent('reset_password', 'changed', '');
            await resetPassword({ userId, tokenId, token, newPassword });
            setSuccessMessage("Password has been reset successfully. You can now login with your new password.");
            setTimeout(() => navigate('/'), 5000);
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred while resetting the password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid welcome-container d-flex justify-content-center align-items-center">
            <div className="auth-box">
                <div className="welcome-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <h2 className="mb-3">Reset Password</h2>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {successMessage && (
                            <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <form onSubmit={handleResetPassword} className="mb-3">
                            <div className="form-group mb-3">
                                <label>New Password</label>
                                <input type="password" className="form-control" onChange={e => setNewPassword(e.target.value)} />
                            </div>
                            <div className="form-group mb-3">
                                <label>Confirm Password</label>
                                <input type="password" className="form-control" onChange={e => setConfirmPassword(e.target.value)} />
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Reset Password</button>
                        </form>
                    </div>
                </div>
                <div className="page-footer text-center">
                    <div className="footer-links">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://x.com/xkrasauskas" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                    </div>
                    <p>© 2024 All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
