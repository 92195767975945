import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';

function Register(props) {
    const navigate = useNavigate();
    const { sendEvent, setLoading } = useCommons();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const returnUrl = process.env.REACT_APP_URL;

    const handleRegister = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (password !== repeatPassword) {
            setErrorMessage('Passwords do not match');
            setLoading(false);
            return;
        }

        if (!acceptedTerms) {
            setErrorMessage('You must accept the Terms of Service and Privacy Policy');
            setLoading(false);
            return;
        }

        try {
            const response = await signUp(email, password, returnUrl + '/verify-email');
            const user = response.data.user;
            sendEvent('register', 'registered', '');
            props.onEmailVerification(email);
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred during registration');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container-fluid welcome-container d-flex justify-content-center align-items-center">
            <div className="auth-box">
                <div className="welcome-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <h2 className="mb-3">Register</h2>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        <form onSubmit={handleRegister} className="mb-3">
                            <div className="form-group mb-3">
                                <label>Email</label>
                                <input type="text" className="form-control" onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group mb-3">
                                <label>Password</label>
                                <input type="password" className="form-control" onChange={e => setPassword(e.target.value)} minLength="6" />
                                <p className="form-text">Password must be 6 or more characters</p>
                            </div>
                            <div className="form-group mb-3">
                                <label>Repeat Password</label>
                                <input type="password" className="form-control" onChange={e => setRepeatPassword(e.target.value)} minLength="6" />
                                <p className="form-text">Password must be 6 or more characters</p>
                            </div>
                            <div className="form-group mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="termsCheckbox" checked={acceptedTerms} onChange={e => setAcceptedTerms(e.target.checked)} />
                                <label className="form-check-label" htmlFor="termsCheckbox">
                                    I accept the <a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a> and <a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a>
                                </label>
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Register</button>
                        </form>
                        <button className="btn btn-light w-100" onClick={() => navigate('/')}>Already have an account? Login</button>
                    </div>
                </div>
                <div className="page-footer text-center">
                    <div className="footer-links">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://x.com/xkrasauskas" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                    </div>
                    <p>© 2024 All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default Register;
