import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../logo.png';
import { verifyEmail } from '../api';
import { useCommons } from '../CommonContext';

function EmailVerification({ email: propEmail, onResendEmail }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { setLoading } = useCommons();
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('pending');
    const [email, setEmail] = useState(propEmail);
    const verificationAttempted = useRef(false);

    const verifyEmailToken = async (email, token) => {
        setLoading(true);
        try {
            await verifyEmail(email, token);
            setVerificationStatus('success');
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error) {
            console.error('Email verification failed:', error);
            setVerificationStatus('error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const urlEmail = params.get('email');
        const token = params.get('token');

        if (urlEmail && token && !verificationAttempted.current) {
            const decodedEmail = decodeURIComponent(urlEmail);
            setEmail(decodedEmail);
            verifyEmailToken(decodedEmail, token);
            verificationAttempted.current = true;
        }
        return () => {
            verificationAttempted.current = false;
        };
    }, [location]);

    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setCanResend(true);
        }
        return () => {
            setCanResend(true);
        };
    }, [timer]);

    const handleResendEmail = () => {
        setTimer(60);
        setCanResend(false);
        onResendEmail(email);
    };

    return (
        <div className="container-fluid welcome-container d-flex justify-content-center align-items-center">
            <div className="auth-box">
                <div className="welcome-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <h2 className="mb-3">Email Verification</h2>
                        {verificationStatus === 'pending' && (
                            <>
                                <p>A verification email has been sent to {email}. Please check your inbox and click the verification link.</p>
                                <button
                                    className="btn btn-primary mb-3 w-100"
                                    onClick={handleResendEmail}
                                    disabled={!canResend}
                                >
                                    Resend Email {canResend ? '' : `(${timer}s)`}
                                </button>
                                <button className="btn btn-light w-100" onClick={() => navigate('/')}>Back to Login</button>
                            </>
                        )}
                        {verificationStatus === 'success' && (
                            <>
                                <p className="text-success">Email verified successfully. Redirecting to login page...</p>
                                <button className="btn btn-light w-100" onClick={() => navigate('/')}>Back to Login</button>
                            </>
                        )}
                        {verificationStatus === 'error' && (
                            <>
                                <p className="text-danger">Email verification failed. Please try again or request a new verification email.</p>
                                <button className="btn btn-primary w-100" onClick={handleResendEmail}>
                                    Request New Verification Email
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div className="page-footer text-center">
                    <div className="footer-links">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://x.com/xkrasauskas" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                    </div>
                    <p>© 2024 All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;
