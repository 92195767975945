import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';

function AccountDeleted() {
    const navigate = useNavigate();

    return (
        <div className="container-fluid welcome-container d-flex justify-content-center align-items-center">
            <div className="auth-box">
                <div className="welcome-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <h2 className="mb-3">Account Deleted</h2>
                        <p>Your account has been successfully deleted. I'm are sorry to see you go.</p>
                        <p>I would appreciate it if you could take a moment to let me know why you decided to delete your account. Your feedback would help me build a better project.</p>
                        <p>Email: <a href="mailto:hello@tinyrebrand.com">hello@tinyrebrand.com</a></p>
                        <p>X (Twitter): <a href="https://vclck.com/my-twitter?utm_source=tinyrebrand-app&utm_medium=account-deleted" hrefLang="en" target="_blank">@xkrasauskas</a></p>
                        <button className="btn btn-light w-100" onClick={() => navigate('/')}>Back to Login</button>
                    </div>
                </div>
                <div className="page-footer text-center">
                    <div className="footer-links">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://vclck.com/my-twitter?utm_source=tinyrebrand-app&utm_medium=account-deleted" hrefLang="en" target="_blank">@xkrasauskas</a></div>
                    </div>
                    <p>© 2024 All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default AccountDeleted;
